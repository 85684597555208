import * as React from 'react'
import dynamic from 'next/dynamic'
import ResizeObserver from 'resize-observer-polyfill'

import { useSiteConfig } from '@thg-commerce/enterprise-core'
import {
  pushToEventGA4,
  SortByDataLayerGA4,
} from '@thg-commerce/enterprise-metrics/src/data_layer/pushToDataLayer/utils'
import {
  ProductSort,
  SimpleFacet,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import { spacing, useTheme, ZIndexLevel } from '@thg-commerce/enterprise-theme'
import { CustomDropdown } from '@thg-commerce/gravity-elements'
import { OptionsType } from '@thg-commerce/gravity-elements/CustomDropdown/CustomDropdown'
import { HorizontalAlignment } from '@thg-commerce/gravity-theme/alignments'

import { BrandFacet } from '../FacetComponents'
import { FacetContent } from '../FacetContent/FacetContent'
import { Facet, FacetDataLayerSchema, FacetsProps } from '../types'
import {
  clearFacetSelection,
  displayFacet,
  isSelectedFacet,
  UpdateFacetAction,
  updateFacetSelection,
} from '../utils'

import {
  ActionFilterButton,
  ButtonContainer,
  FacetContainer,
  HorizontalFacetWrapper,
  StyledBlankDropdown,
  StyledHorizontalFacetContent,
} from './styles'

const CONTAINER_HEIGHT = 100

const Plus = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/Plus'),
)
const Minus = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/Minus'),
)
const SvgIcon = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/SvgIcon'),
)

export interface HorizontalFacetsProps extends FacetsProps {
  i18nText: {
    searchBrandLabel: string
    searchBrandPlaceholder: string
    filterText: string
    close: string
    clearAll: string
    sortBy: string
    minimumLabel: string
    maximumLabel: string
    sliderHeader: string
  }
  sortTypes: {
    type: ProductSort
    displayText: string
  }[]
  dropDownOptions: OptionsType[]
  hasBrandSearch?: boolean
  selectedSort?: string | number
  facetStyle: {
    facet: {
      width: string
      maxWidth: string
      numberAlignment: HorizontalAlignment
    }
    sort: {
      width: string
    }
    container: {
      gap: {
        row: number
        column: number
      }
    }
  }
  stickyOnDesktop?: boolean
  headerHeight: number
  hideMargin?: boolean
  facetSchema: FacetDataLayerSchema
}

const FilterIcon = ({ icon }) => (
  <div
    style={{
      marginRight: spacing(0.5),
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
    }}
  >
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox={icon.viewBox}
      width={icon.width}
      height={icon.height}
    >
      <path d={icon.svgPath} fillRule="evenodd" />
    </SvgIcon>
  </div>
)

export const HorizontalFacets = (props: HorizontalFacetsProps) => {
  const theme = useTheme()
  const [showFilteredItems, setShowFilteredItems] = React.useState<boolean>(
    false,
  )
  const containerRef = React.useRef<HTMLDivElement>(null)
  const { useGA4EnhancedEcom, facetsDisplayedOnProductList } = useSiteConfig()
  const [facetsSticky, setFacetsSticky] = React.useState(false)

  const trackingEventRef = React.useRef<HTMLHeadingElement>(null)

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (elements, _) => {
        const targetElement = elements[0]
        if (targetElement && targetElement.isIntersecting) {
          setFacetsSticky(false)
        } else {
          setFacetsSticky(true)
        }
      },
      {
        rootMargin: `-${props.headerHeight + 1}px 0px 0px 0px`,
        threshold: 1,
      },
    )
    trackingEventRef.current && observer.observe(trackingEventRef.current)
  }, [props.headerHeight])
  const [focusIndex, setFocusIndex] = React.useState(0)
  const facets = React.useMemo(() => {
    return props.facets
      .filter(
        (facet) =>
          facet.__typename === Facet.SLIDER ||
          ((facet.__typename === Facet.SIMPLE ||
            facet.__typename === Facet.RANGED) &&
            displayFacet(facet.options)),
      )
      .map((facet) => {
        const sectionCount = props.selectedFacets.find(
          (selection) => selection.facetName === facet.facetName,
        )?.selections.length

        return (
          <StyledBlankDropdown
            key={facet.facetName}
            quantifier={sectionCount}
            zIndex={ZIndexLevel.Base5}
            width={props.facetStyle.facet.width}
            maxWidth={props.facetStyle.facet.maxWidth}
            isBrandFacet={facet.facetName.includes('brand_content')}
            dropdownWidth={
              facet.facetName.includes('brand_content') ? '1000px' : undefined
            }
            useDefaultDropdownWidth={!facet.facetName.includes('brand_content')}
            buttonText={facet.facetHeader}
            disabledClearAll={sectionCount === undefined}
            ariaHasPopup="listbox"
            onCustomButtonClick={() => {
              props.onInputChange({
                facets: clearFacetSelection(
                  facet.facetName,
                  props.selectedFacets,
                ),
              })
            }}
            i18nButtonText={{
              ...props.i18nText,
              customButton: props.i18nText.clearAll,
            }}
            hasBrandSearch={props.hasBrandSearch}
            badgePosition={props.facetStyle?.facet.numberAlignment}
            iconOverride={theme?.icons?.chevronDown}
            focusIndex={focusIndex}
            setFocusIndex={setFocusIndex}
            optionsLength={facet.options?.length || 0}
            selectDropdownOption={() => {
              const facetOptions = facet.options
              const isSelected = isSelectedFacet(
                facet.facetName,
                facetOptions[focusIndex],
                props.selectedFacets,
              )
              const newFacets = updateFacetSelection(
                isSelected
                  ? UpdateFacetAction.RemoveFacet
                  : UpdateFacetAction.AddFacet,
                facet.facetName,
                facet.facetHeader,
                { optionName: facetOptions[focusIndex].optionName },
                props.selectedFacets,
                useGA4EnhancedEcom,
                props.facetSchema,
              )
              props.onInputChange({ facets: newFacets })
            }}
            facetName={facet.facetName}
          >
            {facet.__typename === Facet.SIMPLE &&
            facet.facetName.includes('brand_content') ? (
              <BrandFacet
                {...props}
                facet={facet as SimpleFacet}
                hasBrandSearch={true}
                useGA4EnhancedEcom={useGA4EnhancedEcom}
                facetSchema={props.facetSchema}
              />
            ) : (
              <FacetContent
                isHorizontalFacet
                facet={facet}
                maxHeight="216px"
                selectedFacets={props.selectedFacets}
                onInputChange={props.onInputChange}
                i18nText={props.i18nText}
                useGA4EnhancedEcom={useGA4EnhancedEcom}
                facetSchema={props.facetSchema}
              />
            )}
          </StyledBlankDropdown>
        )
      })
  }, [props, focusIndex, theme?.icons?.chevronDown, useGA4EnhancedEcom])

  const [showFilterButton, setShowFilterButton] = React.useState<boolean>(
    !!facetsDisplayedOnProductList &&
      facets.length >= facetsDisplayedOnProductList,
  )

  React.useEffect(() => {
    if (!containerRef?.current) {
      return
    }
    let resizeObserver: ResizeObserver | undefined
    resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      const height = entries[0]?.contentRect.height || 0
      if (showFilterButton !== height > CONTAINER_HEIGHT) {
        setShowFilterButton(height > CONTAINER_HEIGHT)
      }
    })
    resizeObserver?.observe(containerRef.current)

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect()
      }
    }
  }, [facets?.length, showFilterButton, containerRef?.current])

  return (
    <HorizontalFacetWrapper
      isAlignTop={showFilterButton}
      stickyOnDesktop={props.stickyOnDesktop}
      scrolled={facetsSticky}
      hideMargin={props.hideMargin}
      ref={trackingEventRef}
    >
      <StyledHorizontalFacetContent
        data-testid="horizontal-facet-wrapper"
        showFilteredItems={showFilteredItems}
      >
        <FacetContainer
          data-testid="horizontal-facet-dropdowns-container"
          ref={containerRef}
          gap={props.facetStyle.container.gap}
        >
          <CustomDropdown
            addEntryKey={true}
            stickyPosition
            zIndex={ZIndexLevel.Base5}
            placeholder={props.i18nText.sortBy}
            aria-label={props.i18nText.sortBy}
            data-testid="sort-by-dropdown"
            options={props.dropDownOptions}
            useDefaultDropdownWidth
            width={props.facetStyle.sort.width}
            height={
              theme.widget.productList?.horizontalFacets?.wrapper
                ?.facetWrapperHeight
            }
            onChange={(event) => {
              props.onInputChange({
                sort: props.sortTypes.find((sort) => sort.type === event.key)
                  ?.type,
              })

              useGA4EnhancedEcom &&
                pushToEventGA4<SortByDataLayerGA4>({
                  event: 'sort_by',
                  sort_method:
                    props.sortTypes.find((sort) => sort.type === event.key)
                      ?.type || '',
                })
            }}
            selected={props.selectedSort}
            hasPlaceholderAsLabel
            iconOverride={theme?.icons?.chevronDown}
          />
          {facets}
        </FacetContainer>
      </StyledHorizontalFacetContent>

      <ButtonContainer>
        {props.selectedFacets.length > 0 && (
          <ActionFilterButton
            emphasis="low"
            onClick={() => {
              props.onInputChange({
                facets: [],
              })
              setShowFilteredItems(false)
            }}
            data-testid="clear-all-button"
          >
            {props.i18nText.clearAll}
          </ActionFilterButton>
        )}
        {showFilterButton && (
          <ActionFilterButton
            emphasis="low"
            onClick={() => setShowFilteredItems((state) => !state)}
            data-testid="filter-button"
            tabIndex={-1}
          >
            {theme.icons?.minus && theme.icons?.plus ? (
              showFilteredItems ? (
                <FilterIcon icon={theme.icons.minus} />
              ) : (
                <FilterIcon icon={theme.icons.plus} />
              )
            ) : showFilteredItems ? (
              <Minus />
            ) : (
              <Plus />
            )}
            {props.i18nText.filterText}
          </ActionFilterButton>
        )}
      </ButtonContainer>
    </HorizontalFacetWrapper>
  )
}
